var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",style:(_vm.cssVars),attrs:{"id":"main"}},[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"container"},[(
          _vm.customization &&
          _vm.customization.artworkCustomizationEnabled &&
          _vm.customization.artworkBackgroundColor
        )?_c('div',{staticClass:"bg-image"}):_vm._e(),(
          (_vm.customization && !_vm.customization.artworkCustomizationEnabled) ||
          (_vm.customization && !_vm.customization.artworkBackgroundColor)
        )?_c('img',{staticClass:"bg-image",attrs:{"src":_vm.bgImage,"alt":""}}):_vm._e()]),(_vm.artworkError)?_c('main',{staticClass:"error"},[_c('h2',[_vm._v(_vm._s(_vm.$t("no_artwork")))])]):_vm._e(),(!_vm.isLoading && _vm.artwork && _vm.artwork.locale)?_c('main',[_c('div',{staticClass:"artwork"},[(_vm.artwork && _vm.artwork.mainImageUrl)?_c('img',{staticClass:"artwork-image",attrs:{"src":_vm.isNetworkOnline ? _vm.getImageUrl(_vm.artwork.mainImageUrl) : _vm.imageArtwork || _vm.artwork.mainImageUrl,"alt":_vm.artwork.locale.name}}):_vm._e()]),_c('h2',[_vm._v(_vm._s(_vm.artwork.locale.name))]),_c('div',{staticClass:"artwork-subtitle"},[_c('h3',[_vm._v(_vm._s(_vm.artwork.author))]),(_vm.artwork.showCode)?_c('div',{staticClass:"artwork-title-code"},[_c('i',{staticClass:"mm-icon mm-icon-audio white"}),_vm._v(" "+_vm._s(_vm.artwork.code)+" ")]):_vm._e()]),_c('h4',[_vm._v(_vm._s(_vm.artwork.locale.artworkDate))]),(_vm.artwork.locale.mainAudioUrl)?_c('audio-player',{staticClass:"audio-player",attrs:{"file":_vm.artwork.locale.mainAudioUrl,"text-color":_vm.customization &&
          _vm.customization.artworkCustomizationEnabled &&
          _vm.customization.artworkTextColor
            ? _vm.customization.artworkTextColor
            : '#FFFFFF',"icons-color":_vm.customization &&
          _vm.customization.artworkCustomizationEnabled &&
          _vm.customization.artworkTextColor
            ? _vm.customization.artworkTextColor
            : '#FFFFFF',"additionalInformation":_vm.getAdditionalInformation()}}):_vm._e(),(_vm.artwork.locale.mainText)?_c('p',[_vm._v(_vm._s(_vm.artwork.locale.mainText))]):_vm._e()],1):_vm._e(),_c('footer',{style:(_vm.footerCSSVars)},[_c('div',{staticClass:"footer-buttons"},[_c('button',{staticClass:"button-white",on:{"click":function($event){return _vm.goBack()}}},[_c('i',{staticClass:"mm-icon mm-icon-close big"})]),(
            _vm.artwork && _vm.artwork.extraResources && _vm.artwork.extraResources.length
          )?_c('button',{staticClass:"button-resources",style:(_vm.footerButtonColorCSSVar),on:{"click":function($event){_vm.isResourceListOpen = !_vm.isResourceListOpen}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isResourceListOpen),expression:"!isResourceListOpen"}],staticClass:"mm-icon mm-icon-add white big"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isResourceListOpen),expression:"isResourceListOpen"}],staticClass:"mm-icon mm-icon-remove white big"})]):_vm._e(),(_vm.museum.recognitionAvailable)?_c('button',{staticClass:"button-recognition",on:{"click":function($event){return _vm.goToRecognition()}}},[(_vm.customization && _vm.customization.recognitionIconUrl)?_c('img',{staticClass:"recognition-icon",attrs:{"src":_vm.customization.recognitionIconUrl,"alt":""}}):_c('img',{staticClass:"recognition-icon",attrs:{"src":require("@/assets/icons/pointplay.png"),"alt":""}})]):_vm._e()]),(_vm.artwork && _vm.artwork.extraResources)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isResourceListOpen),expression:"isResourceListOpen"}],staticClass:"resources",style:([_vm.footerCSSVars, _vm.footerButtonColorCSSVar])},_vm._l((_vm.artwork.extraResources),function(resource){return _c('li',{key:resource.idResource,on:{"click":function($event){return _vm.goToResource(resource)}}},[(resource.resourceType === 'AUDIO')?_c('i',{staticClass:"mm-icon mm-icon-audio white"}):_vm._e(),(resource.resourceType === 'IMAGE')?_c('i',{staticClass:"mm-icon mm-icon-picture white"}):_vm._e(),(resource.resourceType === 'VIDEO')?_c('i',{staticClass:"mm-icon mm-icon-video white"}):_vm._e(),(resource.resourceType === 'TEXT')?_c('i',{staticClass:"mm-icon mm-icon-info white"}):_vm._e(),_c('p',{staticClass:"resource-title"},[_vm._v(_vm._s(resource.title))])])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }